import api from '@/base/utils/request';
import download from "@/base/utils/download";

export const getOptions = (id) => {
  return api({
    url: '/admin/vote/vote/options',
    method: 'post',
    data: {id}
  })
}

export const getDetail = (data) => {
  return api({
    url: '/admin/vote/vote/stats',
    method: 'post',
    data
  })
}

// 导出
export const exportDocument = data => {
  return download({
    url: '/admin/vote/vote/statsExport',
    method: 'post',
    data,
    download: true,
  });
};
