<template>
  <LayoutFilter :onFilter="ok" :collapse-height="60" :onReset="resetForm" :toggleCollapseEnable="false" :onExport="onExport"
                ref="layoutFilter">
    <el-form :model="form" ref="logFilterForm" label-width="70px" size="medium" class="filter-from">
      <div class="filter-item">
        <el-form-item label="会员名称：" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入" clearable @keyup.enter.native="ok"/>
        </el-form-item>
        <el-form-item label="会内职务：" prop="positionIds">
          <el-cascader v-model="positionIds"
                       :options="parentPositionList" :props="props"
                       placeholder="请选择" clearable>
          </el-cascader>
        </el-form-item>
        <el-form-item label="选项：" prop="option_id">
          <el-select v-model="option_id" placeholder="请选择" clearable>
            <el-option v-for="item in options" :key="item.id" :label="item.item_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
  import LayoutFilter from "@/base/layout/LayoutFilter";
  import {positionLevel} from "@/modules/organization-admin/api/member/member-list";
  import {exportDocument, getOptions} from "@/modules/vote/api/vote-analysis";

  export default {
    model: {
      prop: "filterForm",
      event: "updateFilter",
    },
    props: {
      uploadFilter: {
        type: Function,
        required: true,
      },
      filterForm: {
        type: Object,
      },
    },
    data() {
      return {
        // 联级选择配置
        props: {
          value: "id",
          label: "name",
          expandTrigger: "hover",
          children: "position",
        },
        parentPositionList: [],
        options: [],
      };
    },
    methods: {
      // 获取架构职务
      getPositionLevel() {
        positionLevel({is_list_tree: 0, is_show_all: 1})
          .then((res) => {
            this.parentPositionList = res.data;
          })
          .catch(() => {
          });
      },
      //重置表单
      resetForm() {
        this.$refs["logFilterForm"].resetFields();
        this.uploadFilter(this.filterForm);
      },
      //筛选
      ok() {
        this.uploadFilter(this.filterForm);
      },
      // 导出会员列表
      onExport() {
        const postData = {...this.filterForm};
        return exportDocument(postData).catch(() => {
        });
      },
      getVoteOptions() {
        getOptions(this.filterForm.id).then(res => {
          this.options = res.data;
        }).catch(err => {
        })
      }
    },
    created() {
      this.getPositionLevel();
      this.getVoteOptions();
      this.$nextTick(() => {
        this.$layoutFilter = this.$refs.layoutFilter;
      });
    },
    computed: {
      form: {
        get() {
          return this.filterForm;
        },
        set(val) {
          this.$emit("updateFilter", val);
        },
      },
      positionIds: {
        get() {
          return this.form.positionIds;
        },
        set(val) {
          this.form.positionIds = val;
        },
      },
      option_id: {
        get() {
          if (this.form.option_id === -1) return ''
          return this.form.option_id
        },
        set(val) {
          if (val === '') this.form.option_id = -1
          this.form.option_id = val
        }
      }
    },
    components: {
      LayoutFilter,
    },
  };
</script>
