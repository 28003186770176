<template>
  <div class="page" v-loading="loading">
    <div v-if="info" class="config-section">
      <div class="config-section-title">基础信息</div>
      <el-form label-width="90px" label-position="right">
        <el-form-item label="投票标题：">{{info.title}}</el-form-item>
      </el-form>
      <el-form label-width="90px" label-position="right" inline>
        <el-form-item label="投票方式：" style="min-width: 140px">{{info.more_text}}</el-form-item>
        <el-form-item label="参与人数：" style="min-width: 140px">{{info.user_count}}</el-form-item>
        <el-form-item label="投票票数：" style="min-width: 140px">{{info.records_count}}</el-form-item>
      </el-form>
      <el-form label-width="90px" label-position="right">
        <el-form-item label="投票类型：">{{info.type_text}}</el-form-item>
        <el-form-item label="投票时间：">{{info.vote_time}}</el-form-item>
        <el-form-item label="投票票数：">{{info.vote_count}}</el-form-item>
        <el-form-item label="投票结果：">{{info.is_open_text}}</el-form-item>
      </el-form>
    </div>

    <div v-if="info" class="config-section">
      <div class="config-section-title">投票结果</div>
      <el-form label-width="90px" label-position="right">
<!--        <el-form-item :label="` `">-->
          <div class="grid">
            <template v-for="(item, index) in info.myOptions">
              <!--              <div class="flex progress-item" :key="index">-->
              <span style="min-width: 90px;" class="el-form-item__label">{{item.item_name}}</span>
              <el-progress :text-inside="true" :stroke-width="26" class="flex-align-center" :percentage="item.percent" :show-text="true" :color="randomColor()" />
              <span class="el-form-item__label" style="padding-left: 12px;">{{item.records_count}}票 / {{info.records_count}}票</span>
              <!--              </div>-->
            </template>
          </div>
<!--        </el-form-item>-->
      </el-form>
    </div>

    <div v-if="info" class="config-section">
      <div class="config-section-title">图表报告</div>
      <div class="graphic" :style="{
          width: figureType === 'bar' ? '' : '548px',
        }">
        <div class="flex-between" style="margin-bottom: 16px;">
          <div class="update-time" v-if="updateTime"><span style="margin-right: 8px;"
                                                           class="el-icon-info"/>更新时间：{{updateTime}}
          </div>
          <el-select v-model="figureType" size="mini">
            <el-option v-for="op in typeArr" :key="op.label" :label="op.name" :value="op.label">
              <span>{{ op.name }}</span>
              <img class="option-icon" :src="figureType === op.label ? op.actived_icon : op.default_icon"/>
            </el-option>
          </el-select>
        </div>
        <div class="content" ref="chartContent" v-loading="loading"></div>
      </div>
    </div>

    <div v-if="info" class="config-section">
      <div class="config-section-title">列表报告</div>
      <list-layout ref="listLayout" :filter-form="query" :thead="thead" :on-fetch="getTableData">
        <list-filter slot="filter" :filter-form="query" :upload-filter="onFilter"/>
        <el-button slot="item_result" slot-scope="{row}" type="text" @click="showResult(row)">查看结果</el-button>
      </list-layout>
    </div>

    <!-- 结果弹窗 -->
    <el-dialog :visible="resultDialog.show" width="500px"
               title="投票结果" class="dialog-vertical" close-on-click-modal
               @close="resultDialog.show = false">
      <div style="height: 300px; overflow: hidden; ">
        <el-scrollbar>
          <div class="flex progress-item" v-for="(item, index) in resultDialog.data.options" :key="index">
            <span>{{item.item_name}}</span>
            <el-progress style="flex: 1; margin-left: 20px; "
                         :percentage="item.percent" :format="format" :color="randomColor()" />
            <span>{{item.records_count}}票 / {{info.records_count}}票</span>
          </div>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {getDetail} from "@/modules/vote/api/vote-analysis";
  import MyPieChart from "../assets/echarts/pie-chart";
  import MyBarChart from "../assets/echarts/bar-chart";
  import ListFilter from "@/modules/vote/components/vote-analysis/ListFilter";
  import ListLayout from "@/base/layout/ListLayout";

  export default {
    name: "vote-analysis",
    components: {ListLayout, ListFilter},
    data() {
      return {
        loading: true,
        query: {
          id: 0,
          option_id: -1,
          nickname: '',
          positionIds: [],
          page: 1,
          page_size: 15
        },
        info: null,
        updateTime: '',
        // 图表实例
        $chart: null,
        chartData: [],
        figureType: '',
        typeArr: [
          {
            name: "基础柱状图",
            label: "bar",
            default_icon: require("../assets/images/data-stats/bar.png"),
            actived_icon: require("../assets/images/data-stats/bar__actived.png"),
          },
          {
            name: "环形图",
            label: "annular",
            default_icon: require("../assets/images/data-stats/annular.png"),
            actived_icon: require("../assets/images/data-stats/annular__actived.png"),
          },
          {
            name: "饼图",
            label: "pie",
            default_icon: require("../assets/images/data-stats/pie.png"),
            actived_icon: require("../assets/images/data-stats/pie__actived.png"),
          },
          {
            name: "南丁格尔玫瑰图",
            label: "rose",
            default_icon: require("../assets/images/data-stats/rose.png"),
            actived_icon: require("../assets/images/data-stats/rose__actived.png"),
          },
        ],
        // x轴坐标数据
        xData: [],
        thead: [
          {label: 'ID', type: 'index'},
          {label: '会员名称', prop: 'nickname'},
          {label: '会内职务', prop: 'title_names', minWidth: 220},
          // {label: '投票结果', type: 'slot', prop: "result", minWidth: 200}
          {label: '投票结果', prop: "vote_record", minWidth: 200}
        ],
        resultDialog: {
          show: false,
          data: {}
        }
      }
    },
    watch: {
      figureType() {
        this.$nextTick(() => {
          this.destroyChart()
          this.transformData()
          this.initChart()
        })
      }
    },
    created() {
      this.query.id = Number(this.$route.query.id)
      this.fetchResult()
    },
    beforeDestroy() {
      this.destroyChart();
    },
    methods: {
      randomColor() {
        const statusList = [
          'rgba(64, 158, 255, 1)',
          'rgba(144, 147, 153, 1)',
          'rgba(103, 194, 58, 1)',
          'rgba(230, 162, 60, 1)',
          'rgba(245, 108, 108, 1)'
        ]
        return statusList[Math.round(Math.random() * 5)]
      },
      showResult(item) {
        this.resultDialog.data = item;
        this.resultDialog.show = true;
      },
      //转换数据格式
      transformData() {
        if (this.figureType === 'bar') {
          this.chartData = this.info.myOptions.map(item => item.records_count)
          this.xData = this.info.myOptions.map((item) => item.item_name);
        } else if (["pie", "annular", "rose"].includes(this.figureType)) {
          this.chartData = this.info.myOptions.map(({percent, item_name}) => ({
            name: item_name,
            value: percent
          }));
        }
      },
      // 初始化图表
      initChart() {
        if (!this.$chart) {
          try {
            switch (this.figureType) {
              case "bar":
                this.$chart = new MyBarChart({
                  container: this.$refs.chartContent,
                  data: this.chartData,
                  xData: this.xData,
                });
                break;
              case "annular":
                this.$chart = new MyPieChart({
                  container: this.$refs.chartContent,
                  data: this.chartData,
                  series: {
                    radius: ["50%", "70%"],
                  },
                });
                break;
              case "rose":
                this.$chart = new MyPieChart({
                  container: this.$refs.chartContent,
                  data: this.chartData,
                  series: {
                    radius: [10, 100],
                    roseType: "radius",
                    itemStyle: {
                      borderRadius: 6,
                    },
                  },
                });
                break;
              case "pie":
                this.$chart = new MyPieChart({
                  container: this.$refs.chartContent,
                  data: this.chartData,
                  series: {
                    radius: ["0%", "70%"],
                  },
                });
                break;
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          this.setChartOption();
        }
      },
      //设置图表
      setChartOption() {
        this.$chart.setOption(this.chartData);
      },
      // 销毁实例
      destroyChart() {
        if (this.$chart) {
          this.$chart.dispose();
          this.$chart = null;
        }
      },
      fetchResult(callback) {
        getDetail({
          ...this.query
        }).then(res => {
          this.info = res.data.info
          this.updateTime = res.data.update_time
          if (this.chartData && this.chartData.length) {} else {
            this.chartData = this.info.myOptions
            this.figureType = 'bar'
          }
          this.loading = false;
          callback && callback({data: res.data.list})
        }).catch(err => {
          this.loading = false;
        })
      },
      onFilter(query) {
        this.$refs.listLayout.getList(query)
      },
      getTableData(query) {
        this.query = Object.assign({}, this.query, query)
        return new Promise((resolve, reject) => {
          this.fetchResult((res) => {
            resolve(res)
          })
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .page {
    min-height: 70vh;

    .update-time {
      font-size: 12px;
      color: #919398;
      line-height: 24px;
    }
  }

  .el-form-item {
    margin-bottom: 10px;
  }

  .graphic {
    background-color: #f2f3f5;
    padding: 24px;
    width: 100%;
    height: 100%;
    position: relative;

    .content {
      height: 350px;
      width: 100%;
      background-color: white;
      padding: $space;
    }
  }

  .config-section + .config-section {
    margin-top: $space * 2;
  }

  ::v-deep.el-select-dropdown__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .option-icon {
    width: 16px;
    height: 16px;
  }

  .grid {
    display: grid;
    grid-template-columns: max-content 400px auto;
    width: fit-content;
    align-items: center;
  }

  .progress-item {
    padding: 0 $space;

    &+.progress-item {
      margin-top: $space;
    }
  }
</style>
